module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dr. Mike Maynard","short_name":"Dr. Mike","start_url":"/","display":"minimal-ui","icon":"src/images/phoenix-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7d21450d47ca24d0bee2c8c01559df75"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
